/* eslint-disable jsx-a11y/media-has-caption */
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { lockedAnimation } from '../../../../assets';
import { Spinner } from '../../../../components/Elements';
import { findFirstUnviewedResource } from '../../../../utils/misc';
import {
  useGetCourseDetails,
  useGetCourseProgress,
} from '../api/courseProgress';
import { Details } from '../components/Details';
import { Media } from '../components/Media';
import { Resources } from '../components/Resources';

export const CourseContent = () => {
  const { id } = useParams();
  const [openSectionIndex, setOpenSectionIndex] = useState<number | null>(0);
  const [openResourceIndex, setOpenResourceIndex] = useState<number | null>(0);

  const [currentSectionIndex, setCurrentSectionIndex] = useState<number | null>(
    0,
  );

  const {
    data: courseProgress,
    isLoading: isCourseProgressLoading,
    isError: isCourseProgressError,
    error: courseProgressError,
  } = useGetCourseProgress(id!);

  const {
    data: courseDetails,
    isLoading: isCourseDetailsLoading,
    isError: isCourseDetailsError,
    error: courseDetailsError,
  } = useGetCourseDetails(id!);

  const navigate = useNavigate();

  const setDefaultOpenIndex = (sectionIndex: number, resourceIndex: number) => {
    setOpenSectionIndex(sectionIndex);
    setCurrentSectionIndex(sectionIndex);

    setOpenResourceIndex(resourceIndex);
  };

  useEffect(() => {
    if (
      courseProgress &&
      courseProgress.sections.length &&
      courseProgress.sections[courseProgress.sections.length - 1].resources
        .length
    ) {
      const result = findFirstUnviewedResource(courseProgress);
      setDefaultOpenIndex(result.sectionIndex!, result.resourceIndex!);
    }
  }, [courseProgress]);

  if (isCourseProgressLoading || isCourseDetailsLoading) {
    return (
      <div className='flex min-h-screen items-center justify-center'>
        <Spinner />
      </div>
    );
  }

  if (
    isCourseProgressError ||
    isCourseDetailsError ||
    !courseProgress ||
    !courseDetails
  ) {
    return (
      <div className='flex min-h-screen items-center justify-center'>
        <div className='text-center'>
          {courseProgressError?.cause === 403 ? (
            <div className='flex flex-col items-center justify-center'>
              <img src={lockedAnimation} alt='locked gif' />
              <p>You do not have access to view this course.</p>
            </div>
          ) : (
            <p className='text-red-500'>
              {courseProgressError?.message ||
                courseDetailsError?.message ||
                'Invalid course ID. Please try again.'}
            </p>
          )}

          <button
            onClick={() => navigate('/student/my-courses')}
            className='mt-4 px-4 py-2 bg-primary hover:bg-secondary text-white rounded'
          >
            Go back to courses
          </button>
        </div>
      </div>
    );
  }

  const { course_title, sections: sectionsProgress } = courseProgress!;
  const { created_by, sections: sectionsDetails } = courseDetails!;

  return (
    <div className='flex flex-col w-full bg-white'>
      <div className='mb-4 mt-4 sm:mt-0'>
        <div className='flex items-center'>
          <button onClick={() => navigate(-1)}>
            <ArrowLeftIcon className='h-4 w-4 mr-2' />
          </button>

          <h3 className='font-semibold'>{course_title}</h3>
        </div>

        <h4 className='mt-2 font-thin'>
          By {created_by.full_name ?? 'Dr. Opeyemi Olaonipekun'}
        </h4>
      </div>

      <div className='flex flex-col xl:flex-row min-h-[80vh] mb-4'>
        {sectionsDetails.length ? (
          <>
            <div className='flex-1 lg:flex-[0.65] '>
              <Media
                openResourceDetails={
                  sectionsDetails[Number(currentSectionIndex)].resources[
                    Number(openResourceIndex)
                  ]
                }
              />
              <div className='min-h-[300px]'>
                <Details
                  openResourceDetails={
                    sectionsDetails[Number(currentSectionIndex)].resources[
                      Number(openResourceIndex)
                    ]
                  }
                  openResourceProgress={
                    sectionsProgress[Number(currentSectionIndex)].resources[
                      Number(openResourceIndex)
                    ]
                  }
                  courseDescription={courseDetails.description}
                  courseID={courseDetails.id}
                  sectionID={sectionsDetails[Number(currentSectionIndex)].id}
                />
              </div>
            </div>
            <div className='flex-1 lg:flex-[0.35]'>
              <Resources
                sectionsDetails={sectionsDetails}
                sectionsProgress={sectionsProgress}
                openSectionIndex={openSectionIndex}
                openResourceIndex={openResourceIndex}
                setOpenSectionIndex={setOpenSectionIndex}
                setOpenResourceIndex={setOpenResourceIndex}
                currentSectionIndex={currentSectionIndex}
                setCurrentSectionIndex={setCurrentSectionIndex}
              />
            </div>
          </>
        ) : (
          'No content has been uploaded for this course yet.'
        )}
      </div>
    </div>
  );
};
