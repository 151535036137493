import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { Head } from '../../../../components/Head';
import { removeEmptyProperties } from '../../../../utils/converter';
import { useGetLiveSessionWithCoursePopulated } from '../api/getLiveSession';
import { useUpdateUpcomingLiveSession } from '../api/updateGeneralInformation';
import { LiveSessionForm } from '../components/LiveSessionForm';
import { Resource, ScheduleLiveSessionInput } from '../types';

export const EditLiveSession: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    mutate: updateLiveSession,
    isError,
    isSuccess,
    isPending,
    error,
    reset,
  } = useUpdateUpcomingLiveSession();

  const [defaultValues, setDefaultValues] =
    useState<ScheduleLiveSessionInput | null>(null);
  const { data, isFetching, refetch } = useGetLiveSessionWithCoursePopulated(
    id as string,
  );

  useEffect(() => {
    if (data) {
      const dValues = {
        ...data,
        sections: data.sections.map(
          (section: {
            course: string;
            id: string;
            order: number;
            resources: Resource[];
            title: string;
          }) => ({
            ...section,
            section_id: section.id,
          }),
        ),
        image_id: data.image_id?.id,
        presigned_url: data.image_id?.presigned_url,
        category_id: data.category?.id,
        capacity: String(data?.capacity),
      };

      if (!data.questions?.length) {
        console.log('no questions');
        dValues['questions'] = [
          { question_text: '' /*type: 'text', options: []*/ },
          { question_text: '' /*type: 'text', options: []*/ },
        ];
      }
      setDefaultValues(dValues);
    }
  }, [id, data, isFetching]);

  if (!defaultValues || isFetching) return <div>Loading...</div>;

  const handleSubmit = (data: ScheduleLiveSessionInput) => {
    const cleanedPayload = removeEmptyProperties(data);
    updateLiveSession({ ...cleanedPayload, id: id! });
  };

  const handleCancel = () => {
    navigate('/admin/live-sessions');
  };

  return (
    <>
      <Head title='Edit Live Session' />
      <LiveSessionForm
        defaultValues={defaultValues}
        mode='edit'
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        refetchLiveSession={refetch}
        isSubmitting={isPending}
        isSuccess={isSuccess}
        isError={isError}
        errorMsg={error ? (error as any).response?.data : null}
        successMsg='Live Session Updated Successfully.'
        reset={reset}
      />
      <ToastContainer />
    </>
  );
};
