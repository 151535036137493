import { TrashIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { XMarkIcon, PlusIcon } from '@heroicons/react/24/solid';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Select, { SingleValue } from 'react-select';

import { Spinner } from '../../../../components/Elements';
import { ErrorProps } from '../../../../types';
import getErrorMsg from '../../../../utils/getErrorMsg';
import { notifyError, notifySuccess } from '../../../../utils/notifications';
import styles from '../../../../utils/styles';
import { Loader } from '../../../liveworkshops/components/Loader';
import { useGetCategories } from '../api/getLiveSession';
import { useUploadImage } from '../api/scheduleLiveSession';
import {
  useCreateNewResource,
  useCreateNewSection,
  useDeleteResource,
  useDeleteSection,
  useUpdateModuleTitle,
} from '../api/updateCourseContent';
import { BannerUploader } from '../components/BannerUploader';
import { daysOfWeek } from '../data';
import {
  Category,
  LiveSessionDetailsResp,
  Resource,
  ScheduleLiveSessionDefaultValues,
  ScheduleLiveSessionInput,
  Section,
  scheduleLiveSessionSchema,
} from '../types';

type LiveSessionFormProps = {
  defaultValues: ScheduleLiveSessionDefaultValues;
  mode: 'create' | 'edit' | 'view';
  onSubmit: (data: ScheduleLiveSessionInput) => void;
  onCancel: () => void;
  isSubmitting?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMsg?: any;
  successMsg?: string;
  reset?: any;
  refetchLiveSession?: any;
};

export const LiveSessionForm: React.FC<LiveSessionFormProps> = ({
  defaultValues,
  mode,
  onSubmit,
  onCancel,
  isSubmitting,
  isSuccess,
  isError,
  successMsg,
  errorMsg,
  reset,
}) => {
  const isEditMode = mode === 'edit';
  const isViewMode = mode === 'view';

  const { id } = useParams();

  const {
    register,
    control,
    formState: { errors },
    watch,
    setValue,
    getValues,
    handleSubmit,
    reset: resetForm,
  } = useForm<ScheduleLiveSessionInput>({
    resolver: zodResolver(scheduleLiveSessionSchema),
    defaultValues,
  });

  const {
    fields: questions,
    append: appendCustomField,
    remove: removeCustomField,
  } = useFieldArray({
    control,
    name: 'questions',
  });

  const {
    fields: sections,
    append: appendSectionField,
    remove: removeSectionField,
    update: updateSectionField,
    replace: replaceSections,
  } = useFieldArray({
    control,
    name: 'sections',
  });

  const [selectedDays, setSelectedDays] = useState<string[]>(
    defaultValues.class_days,
  );

  const [customFieldTypes, setCustomFieldTypes] = useState(
    questions.map((field) => field.question_text),
  );

  const [isEditingContent, setIsEditingContent] = useState(mode === 'edit');
  const [serverErrors, setServerErrors] = useState(null);
  const [selectedSection, setSelectedSection] = useState('');
  const [triggerRender, setTriggerRender] = useState(false);
  const [selectedResource, setselectedResource] = useState('');
  const [selectedSectionTitleIndex, setSelectedSectionTitleIndex] =
    useState<number>();
  const [uploadedFile, setUploadedFile] = useState<File>();

  const { data: categories, isFetching } = useGetCategories();

  const {
    mutate: uploadImage,
    data: imgData,
    isPending: isImgUploadPending,
    isSuccess: isImgUploadSuccess,
    isError: isImgUploadError,
    reset: resetImgUpload,
  } = useUploadImage();

  const queryClient = useQueryClient();

  const {
    mutate: createNewSection,
    isPending: isCreateNewSectionPending,
    isSuccess: isCreateNewSectionSuccess,
    isError: isCreateNewSectionError,
    reset: resetCreateNewSection,
    data: createdSection,
  } = useCreateNewSection();

  const {
    mutate: createNewResource,
    isPending: isCreateNewResourcePending,
    isSuccess: isCreateNewResourceSuccess,
    isError: isCreateNewResourceError,
    reset: resetCreateNewResource,
    data: createdResource,
    error: createNewResourceError,
  } = useCreateNewResource();

  const {
    mutate: deleteSection,
    isPending: isDeleteSectionPending,
    isSuccess: isDeleteSectionSuccess,
    isError: isDeleteSectionError,
    reset: resetDeleteSection,
    data: deletedSection,
  } = useDeleteSection();

  const {
    mutate: deleteResource,
    isPending: isDeleteResourcePending,
    isSuccess: isDeleteResourceSuccess,
    isError: isDeleteResourceError,
    reset: resetDeleteResource,
    data: deletedResource,
    error: deletedResourceError,
  } = useDeleteResource();

  const {
    mutate: updateModuleTitle,
    isPending: isUpdatingTitle,
    isSuccess: isUpdatingTitleSuccess,
    isError: isUpdatingTitleError,
    reset: resetUpdateTitle,
  } = useUpdateModuleTitle();

  const navigate = useNavigate();

  useEffect(() => {
    if (categories && categories.length) {
      const category = defaultValues.category_id
        ? categories.find(
            (cat: Category) => cat.id === defaultValues.category_id,
          )
        : categories.find((cat: Category) =>
            cat.name.toLowerCase().includes('live'),
          );

      if (category) {
        setValue('category_id', category.id);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      notifySuccess(successMsg as string, false);
      reset();

      setTimeout(() => {
        navigate('/admin/live-sessions');
      }, 2000);
    }

    if (isError) {
      if (errorMsg?.data) {
        const obj = errorMsg.data;
        setServerErrors(errorMsg.data);
        const firstErrorKey = Object.keys(obj)[0];
        const firstErrorValue = obj[firstErrorKey][0];

        const msg = `${firstErrorKey.charAt(0).toUpperCase() + firstErrorKey.slice(1)}: ${firstErrorValue}`;
        notifyError(msg, false);
      }

      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);

  useEffect(() => {
    if (isCreateNewSectionSuccess) {
      notifySuccess('New module created successfully', 3000);
      resetCreateNewSection();

      updateSectionField(createdSection.order, {
        ...createdSection,
        section_id: createdSection.id,
      });
    }

    if (isCreateNewSectionError) {
      notifyError('An error occured while creating new module', false);
      resetCreateNewSection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isCreateNewSectionSuccess,
    isCreateNewSectionError,
    defaultValues.sections,
  ]);

  useEffect(() => {
    if (isCreateNewResourceSuccess) {
      setUploadedFile(undefined);
      notifySuccess('New resource has been uploaded successfully', false);
      resetCreateNewResource();

      const liveSessionDetails = queryClient.getQueryData([
        `live-session_${id}`,
      ]);

      const index = (sections as Section[]).findIndex(
        (item: Section) => item.section_id === createdResource.section_id,
      );

      updateSectionField(index, {
        ...sections[index],
        resources: (liveSessionDetails as LiveSessionDetailsResp).data.sections[
          index
        ].resources,
      });

      // Force a re-render
      setTriggerRender((prev) => !prev);
    }

    if (isCreateNewResourceError) {
      notifyError(
        createNewResourceError.message ||
          'An error occured while creating new resource',
        false,
      );
      resetCreateNewResource();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isCreateNewResourceSuccess,
    isCreateNewResourceError,
    createdResource,
    sections,
    defaultValues.sections,
  ]);

  useEffect(() => {
    if (isDeleteSectionSuccess) {
      setSelectedSection('');
      notifySuccess('Module deleted successfully', 3000);
      resetDeleteSection();

      replaceSections(
        (sections as Section[]).filter(
          (section) => section.section_id !== deletedSection.id,
        ),
      );
    }

    if (isDeleteSectionError) {
      notifyError('An error occured while deleting module', false);
      resetDeleteSection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteSectionSuccess, isDeleteSectionError, defaultValues.sections]);

  const handleCustomFieldTypeChange = (index: number, type: string) => {
    const newTypes = [...customFieldTypes];
    newTypes[index] = type;
    setCustomFieldTypes(newTypes);
  };

  const toggleDay = (day: string) => {
    setSelectedDays((prevSelectedDays) => {
      const updatedDays: string[] = prevSelectedDays.includes(day)
        ? prevSelectedDays.filter((d) => d !== day)
        : [...prevSelectedDays, day];

      setValue('class_days', updatedDays);
      return updatedDays;
    });
  };

  const toggleIsEditingContent = () => {
    setIsEditingContent(!isEditingContent);
  };

  const handleRemoveResource = (
    onChange: any,
    sectionID: string,
    resourceID: string,
  ) => {
    const sections = getValues('sections');
    const resources = sections.find(
      (s) => (s as any).section_id === sectionID,
    )?.resources;

    const newResources = resources?.filter(
      (resource) => (resource as Resource).id !== resourceID,
    );
    onChange(newResources);
  };

  const onEditClick = (index: number) => {
    if (selectedSectionTitleIndex === index) {
      setSelectedSectionTitleIndex(undefined);
    } else {
      setSelectedSectionTitleIndex(index);
    }
  };

  if (isImgUploadSuccess) {
    setValue('image_id', imgData.data?.id);
    resetImgUpload();
  }

  if (isImgUploadError) {
    notifyError('Failed to upload image. Please try again.', false);
    resetImgUpload();
  }

  if (isDeleteResourceSuccess) {
    setselectedResource('');
    handleRemoveResource(
      deletedResource.successCB,
      deletedResource.section_id,
      deletedResource.id,
    );

    notifySuccess('Resource deleted successfully', 3000);
    resetDeleteResource();
  }

  if (isDeleteResourceError) {
    setselectedResource('');

    notifyError(
      getErrorMsg(deletedResourceError as unknown as ErrorProps),
      false,
    );
    resetDeleteResource();
  }

  useEffect(() => {
    if (isUpdatingTitleSuccess) {
      notifySuccess('Module title has been updated successfully', 3000);
      setSelectedSectionTitleIndex(undefined);
      resetUpdateTitle();
    }

    if (isUpdatingTitleError) {
      notifyError('Module title could not be updated, please try again', false);
      resetUpdateTitle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdatingTitle]);

  if (isFetching) return <div>Loading...</div>;

  return (
    <>
      <div className=''>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3 className='text-primary font-semibold'>General Information</h3>
          <div className='flex flex-col sm:flex-row justify-end mt-4 sm:-mt-8'>
            <button
              onClick={onCancel}
              type='button'
              className='sm:mr-2 mb-2 sm:mb-0 font-semibold flex items-center justify-center text-sm h-[40px] min-w-[68px] px-8 rounded-md border-[1px] border-[#e5e7eb] hover:text-primary hover:border-primary text-[#828282]'
            >
              <XMarkIcon className='h-4 w-4 mr-2' />
              Cancel
            </button>

            {!isViewMode && (
              <button
                disabled={isSubmitting}
                type='submit'
                className='flex font-semibold items-center justify-center bg-primary hover:bg-secondary disabled:bg-slate-400 disabled:hover-bg-slate-500 text-sm h-[40px] min-w-[68px] px-8 rounded-md text-white shadow-all-sides'
              >
                {isSubmitting ? (
                  <Spinner className='mr-2' size='sm' variant='light' />
                ) : (
                  <PlusIcon className='h-4 w-4 mr-2' />
                )}
                Submit
              </button>
            )}
          </div>

          {isEditMode && (
            <div className='flex flex-col sm:flex-row justify-end mt-4'>
              <button
                onClick={toggleIsEditingContent}
                type='button'
                className='mb-2 sm:mb-0 font-semibold flex items-center justify-center text-sm h-[40px] min-w-[68px] px-8 rounded-md border-[1px] border-[#e5e7eb] hover:text-primary hover:border-primary text-[#828282]'
              >
                {isEditingContent
                  ? 'Edit General Information'
                  : 'Edit Course Content'}
              </button>
            </div>
          )}

          {/* mt-8 sm:mt-4 */}
          <div className='flex flex-col sm:flex-row mt-8 sm:mt-0'>
            <div className={`${styles.formControl} flex-[0.5] sm:mr-4`}>
              <label htmlFor='title' className={styles.label}>
                Class Title
                <span className='text-red-500 ml-1'>*</span>
              </label>
              <input
                {...register('title')}
                placeholder='Enter Workshop Title'
                type='text'
                className={`${styles.input} ${isEditingContent ? 'readonly-input' : ''}`}
                data-cy='title'
                readOnly={isViewMode || isEditingContent}
              />
              <p className={styles.error}>{errors.title?.message as string}</p>
            </div>

            <div className={`${styles.formControl} flex-[0.5]`}>
              <label htmlFor='category_id' className={styles.label}>
                Category
                <span className='text-red-500 ml-1'>*</span>
              </label>

              <Controller
                name='category_id'
                control={control}
                render={({ field: { onChange } }) => {
                  return (
                    <Select
                      data-cy='category_id'
                      classNamePrefix='category_id'
                      placeholder='Select category'
                      onChange={(
                        option: SingleValue<{ name: string; id: string }>,
                      ) => {
                        onChange(String(option?.id));
                      }}
                      defaultValue={
                        defaultValues.category_id
                          ? categories.find(
                              (cat: Category) =>
                                cat.id === defaultValues.category_id,
                            )
                          : categories.find((cat: Category) =>
                              cat.name.toLowerCase().includes('live'),
                            )
                      }
                      getOptionLabel={(item) => item.name}
                      getOptionValue={(item) => item.id}
                      options={categories ?? []}
                      isDisabled={isViewMode || isEditingContent}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isDisabled
                            ? '#e5e7eb'
                            : '#fff',
                          borderColor: state.isDisabled ? '#d1d5db' : '#ccc',
                          cursor: state.isDisabled ? 'not-allowed' : 'default',
                        }),
                      }}
                    />
                  );
                }}
              />
              <p className={styles.error}>
                {errors.category_id?.message as string}
              </p>
            </div>
          </div>

          <div className='flex'>
            <div className={`${styles.formControl} flex-[0.5] mr-4`}>
              <label htmlFor='duration_hours' className={styles.label}>
                Class Duration (hours)
                <span className='text-red-500 ml-1'>*</span>
              </label>
              <Controller
                name='duration_hours'
                control={control}
                render={() => {
                  return (
                    <input
                      {...register('duration_hours', { valueAsNumber: true })}
                      placeholder='Specify Hours'
                      type='number'
                      className={`${styles.input} ${isEditingContent ? 'readonly-input' : ''}`}
                      data-cy='duration_hours'
                      readOnly={isViewMode || isEditingContent}
                      step='0.01'
                    />
                  );
                }}
              />

              <p className={styles.error}>
                {errors.duration_hours?.message as string}
              </p>
            </div>

            <div className={`${styles.formControl} flex-[0.5]`}>
              <label htmlFor='duration_weeks' className={styles.label}>
                Number of Weeks
                <span className='text-red-500 ml-1'>*</span>
              </label>

              <input
                {...register('duration_weeks', { valueAsNumber: true })}
                placeholder='Specify Weeks'
                type='number'
                className={`${styles.input} ${isEditingContent ? 'readonly-input' : ''}`}
                data-cy='duration_weeks'
                readOnly={isViewMode || isEditingContent}
              />
              <p className={styles.error}>
                {errors.duration_weeks?.message as string}
              </p>
            </div>
          </div>

          <div className='flex'>
            <div className={`${styles.formControl} flex-[0.5] mr-4`}>
              <label htmlFor='registration_due_date' className={styles.label}>
                Registration Due Date
                <span className='text-red-500 ml-1'>*</span>
              </label>
              <input
                {...register('registration_due_date')}
                placeholder='Specify Registration Due Date'
                type='date'
                min={
                  mode === 'create'
                    ? new Date().toISOString().split('T')[0]
                    : undefined
                }
                className={`${styles.input} ${isEditingContent ? 'readonly-input' : ''}`}
                data-cy='registration_due_date'
                readOnly={isViewMode || isEditingContent}
              />
              <p className={styles.error}>
                {errors.registration_due_date?.message as string}
              </p>
            </div>

            <div className={`${styles.formControl} flex-[0.5]`}>
              <label htmlFor='start_date' className={styles.label}>
                Start Date
                <span className='text-red-500 ml-1'>*</span>
              </label>

              <input
                {...register('start_date')}
                placeholder='Select Date'
                type='date'
                min={
                  mode === 'create'
                    ? new Date().toISOString().split('T')[0]
                    : undefined
                }
                className={`${styles.input} ${isEditingContent ? 'readonly-input' : ''}`}
                data-cy='start_date'
                readOnly={isViewMode || isEditingContent}
              />
              <p className={styles.error}>
                {errors.start_date?.message as string}
              </p>
            </div>
          </div>

          <div className='flex flex-col sm:flex-row'>
            <div className={`${styles.formControl} flex-[0.5] mr-4`}>
              <label htmlFor='class_days' className={styles.label}>
                Class Days
                <span className='text-red-500 ml-1'>*</span>
              </label>

              <div>
                {daysOfWeek.map((day) => (
                  <button
                    key={day}
                    style={{
                      backgroundColor: selectedDays.includes(day)
                        ? '#0D065F'
                        : '#0D065F50',
                      color: selectedDays.includes(day) ? '#FFFFFF' : '#000000',
                    }}
                    onClick={() => toggleDay(day)}
                    className='h-8 w-8 rounded-full mr-2 border-[1px] border-[#0D065F50]'
                    type='button'
                    disabled={isViewMode || isEditingContent}
                  >
                    {day[0].toUpperCase()}
                  </button>
                ))}
              </div>

              <p className={styles.error}>
                {errors.class_days?.message as string}
              </p>
            </div>

            <div className={`${styles.formControl} flex-[0.5]`}>
              <label htmlFor='start_time' className={styles.label}>
                Class Time (GMT)
                <span className='text-red-500 ml-1'>*</span>
              </label>

              <input
                {...register('start_time')}
                placeholder='Select Time'
                type='time'
                className={`${styles.input} ${isEditingContent ? 'readonly-input' : ''}`}
                data-cy='start_time'
                readOnly={isViewMode || isEditingContent}
              />
              <p className={styles.error}>
                {errors.start_time?.message as string}
              </p>
            </div>
          </div>

          <div className='flex flex-col sm:flex-row'>
            <div className={`flex-[0.5] flex flex-col sm:mr-4`}>
              <div className={`${styles.formControl}`}>
                <label htmlFor='capacity' className={styles.label}>
                  Capacity
                </label>
                <input
                  {...register('capacity', { valueAsNumber: true })}
                  placeholder='Specify max capacity'
                  type='number'
                  className={`${styles.input} ${isEditingContent ? 'readonly-input' : ''}`}
                  data-cy='capacity'
                  readOnly={isViewMode || isEditingContent}
                />
                <p className={styles.error}>
                  {errors.capacity?.message as string}
                </p>
              </div>

              <div className={`${styles.formControl}`}>
                <label htmlFor='expiration_date' className={styles.label}>
                  Expiration Date
                </label>

                <input
                  {...register('expiration_date')}
                  placeholder='Select Date'
                  type='date'
                  min={
                    mode === 'create'
                      ? new Date().toISOString().split('T')[0]
                      : undefined
                  }
                  className={`${styles.input} ${isEditingContent ? 'readonly-input' : ''}`}
                  data-cy='expiration_date'
                  readOnly={isViewMode || isEditingContent}
                />
                <p className={styles.error}>
                  {errors.expiration_date?.message as string}
                </p>
              </div>
            </div>
            <div className={`${styles.formControl} flex-[0.5]`}>
              <label htmlFor='about' className={styles.label}>
                About this Course
                <span className='text-red-500 ml-1'>*</span>
              </label>
              <textarea
                id='about'
                maxLength={800}
                className={`${styles.textarea} ${isEditingContent ? 'readonly-input' : ''}`}
                {...register('about')}
                readOnly={isViewMode || isEditingContent}
              />
              <p className={styles.error}>{errors.about?.message as string}</p>
            </div>
          </div>

          <h3 className='text-primary font-semibold'>Pricing</h3>

          <div className='flex mt-4'>
            <div className={`${styles.formControl} flex-[0.5] mr-4`}>
              <label htmlFor='price_usd' className={styles.label}>
                Base Price<span className='text-xs ml-1'>($)</span>
                <span className='text-red-500 ml-1'>*</span>
              </label>
              <input
                {...register('price_usd', { valueAsNumber: true })}
                placeholder='Price in USD'
                type='number'
                step='0.01'
                className={`${styles.input} ${isEditingContent ? 'readonly-input' : ''}`}
                data-cy='price_usd'
                readOnly={isViewMode || isEditingContent}
              />
              <p className={styles.error}>
                {errors.price_usd?.message as string}
              </p>
            </div>

            <div className={`${styles.formControl} flex-[0.5]`}>
              <label htmlFor='vat_percentage' className={styles.label}>
                VAT Percentage (%)
              </label>

              <input
                {...register('vat_percentage', { valueAsNumber: true })}
                placeholder='VAT Percentage'
                type='number'
                step='0.01'
                className={`${styles.input} ${isEditingContent ? 'readonly-input' : ''}`}
                data-cy='vat_percentage'
                readOnly={isViewMode || isEditingContent}
              />
              <p className={styles.error}>
                {errors.vat_percentage?.message as string}
              </p>
            </div>
          </div>

          <div className='flex mt-4'>
            <div className={`${styles.formControl} flex-[0.5] mr-4`}>
              <label htmlFor='discount_percentage' className={styles.label}>
                Discount Amount (%)
              </label>
              <input
                {...register('discount_percentage', { valueAsNumber: true })}
                placeholder='Discount Percentage'
                type='number'
                step='0.01'
                className={`${styles.input} ${isEditingContent ? 'readonly-input' : ''}`}
                data-cy='discount_percentage'
                readOnly={isViewMode || isEditingContent}
              />
              <p className={styles.error}>
                {errors.discount_percentage?.message as string}
              </p>
            </div>
          </div>

          <div className={`${styles.formControl}`}>
            <h3 className='text-primary font-semibold'>Upload Banner</h3>
            <Controller
              control={control}
              name='image_id'
              render={() => {
                return (
                  <BannerUploader
                    initialUrl={defaultValues.presigned_url}
                    onImageUpload={uploadImage}
                    isViewMode={isViewMode}
                    isEditingContent={isEditingContent}
                  />
                );
              }}
            />
            <p className={styles.error}>{errors.image_id?.message as string}</p>
          </div>

          <h3 className='text-primary font-semibold my-4'>
            Customize Application Form
          </h3>

          <div>
            {questions.map((field, index) => (
              <div key={field.id} className='my-2'>
                <div className='flex space-x-2'>
                  <input
                    {...register(`questions.${index}.question_text`)}
                    placeholder='Label'
                    className={`${styles.input} ${isEditingContent ? 'readonly-input' : ''}`}
                    readOnly={isViewMode || isEditingContent}
                  />

                  <Controller
                    name={`questions.${index}.question_text`}
                    control={control}
                    render={() => {
                      return (
                        <Select
                          classNamePrefix={`questions.${index}.question_text`}
                          className='w-full'
                          placeholder='Type'
                          onChange={(option) => {
                            handleCustomFieldTypeChange(
                              index,
                              option?.value as string,
                            );
                          }}
                          defaultValue={{
                            label: 'Text',
                            value: 'text',
                          }}
                          options={[
                            {
                              label: 'Text',
                              value: 'text',
                            },
                            // {
                            //   label: 'Radio',
                            //   value: 'radio',
                            // },
                            // {
                            //   label: 'Dropdown',
                            //   value: 'dropdown',
                            // },
                            // {
                            //   label: 'Date',
                            //   value: 'date',
                            // },
                            // {
                            //   label: 'Checkbox',
                            //   value: 'checkbox',
                            // },
                          ]}
                          isDisabled={isViewMode || isEditingContent}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isDisabled
                                ? '#e5e7eb'
                                : '#fff', // Light gray background when disabled
                              borderColor: state.isDisabled
                                ? '#d1d5db'
                                : '#ccc', // Light gray border when disabled
                              cursor: state.isDisabled
                                ? 'not-allowed'
                                : 'default',
                            }),
                          }}
                        />
                      );
                    }}
                  />

                  <button
                    type='button'
                    onClick={() => removeCustomField(index)}
                    className=' text-slate-400 hover:text-red-500'
                    disabled={isViewMode || isEditingContent}
                  >
                    <TrashIcon className='h-6 w-6' />
                  </button>
                </div>

                {/* {['radio', 'checkbox', 'dropdown'].includes(
                  customFieldTypes[index],
                ) && (
                  <div className='mt-4'>
                    <h4 className={styles.label}>Options</h4>
                    <Controller
                      control={control}
                      name={`questions.${index}.options`}
                      render={({ field: { onChange, value } }) => (
                        <>
                          {value?.map((option: string, optIndex: number) => (
                            <div key={optIndex} className='flex items-center'>
                              <input
                                value={option}
                                onChange={(e) => {
                                  const newOptions = [...value];
                                  newOptions[optIndex] = e.target.value;
                                  onChange(newOptions);
                                }}
                                placeholder={`Option ${optIndex + 1}`}
                                className={`${styles.input} ${isEditingContent ? 'readonly-input' : ''} flex-1`}
                                readOnly={isViewMode}
                              />
                              <button
                                type='button'
                                onClick={() => {
                                  const newOptions = value.filter(
                                    (_: string, i: number) => i !== optIndex,
                                  );
                                  onChange(newOptions);
                                }}
                                className='ml-4 text-slate-400 hover:text-red-500'
                                disabled={isViewMode || isEditingContent}
                              >
                                <TrashIcon className='h-4 w-4' />
                              </button>
                            </div>
                          ))}

                          <button
                            type='button'
                            onClick={() => onChange([...(value || []), ''])}
                            className='mt-2 text-primary hover:underline flex items-center italic font-semibold text-xs mb-6'
                            disabled={isViewMode || isEditingContent}
                          >
                            <PlusIcon className='h-4 w-4 mr-1' />
                            Add Option
                          </button>
                        </>
                      )}
                    />
                  </div>
                )} */}
              </div>
            ))}
            <button
              type='button'
              onClick={() =>
                appendCustomField({
                  question_text: '' /*type: 'text', options: []*/,
                })
              }
              className=' text-primary text-sm flex items-center italic font-semibold'
              disabled={isViewMode || isEditingContent}
            >
              <PlusIcon className='h-4 w-4 mr-1' /> Add New Field
            </button>
          </div>

          {isEditMode && (
            <div className='' key={String(triggerRender)}>
              <h3 className='text-primary font-semibold mt-8'>
                Course Content
              </h3>
              {sections.map((section, sectionIndex) => {
                return (
                  <div key={sectionIndex} className='border p-4 mt-4'>
                    <div className='flex justify-between items-center'>
                      <h4 className='text-lg font-bold text-slate-500'>
                        Module {sectionIndex + 1}
                      </h4>

                      {sectionIndex === sections.length - 1 && (
                        <button
                          type='button'
                          onClick={() => {
                            if (!(section as Section).section_id) {
                              removeSectionField(sectionIndex);
                            } else {
                              setSelectedSection(
                                (section as Section).section_id,
                              );
                              deleteSection({
                                sectionID: (section as Section).section_id,
                                sessionID: id as string,
                              });
                            }
                          }}
                          className='text-slate-400 hover:text-red-500'
                          disabled={isViewMode || !isEditingContent}
                        >
                          {isDeleteSectionPending &&
                          selectedSection ===
                            (section as Section).section_id ? (
                            <Spinner
                              className='mr-2'
                              size='sm'
                              variant='light'
                            />
                          ) : (
                            <TrashIcon className='h-5 w-5' />
                          )}
                        </button>
                      )}
                    </div>
                    <div className='flex flex-col'>
                      <div className='flex'>
                        <div className='flex-1'>
                          <label
                            htmlFor={`sections.${sectionIndex}.title`}
                            className={styles.label}
                          >
                            Title
                            <span className='text-red-500 ml-1'>*</span>
                          </label>
                          <div className='flex'>
                            <input
                              {...register(`sections.${sectionIndex}.title`)}
                              placeholder='Module title'
                              className={`border p-2 rounded w-full ${!isEditingContent ? 'readonly-input' : ''}`}
                              readOnly={
                                isViewMode ||
                                !isEditingContent ||
                                ((section as Section).section_id &&
                                selectedSectionTitleIndex === sectionIndex
                                  ? false
                                  : !!(section as Section).section_id)
                              }
                            />

                            {(section as Section).section_id && (
                              <button
                                onClick={() => onEditClick(sectionIndex)}
                                type='button'
                                disabled={isViewMode}
                                className='text-slate-400 ml-2 hover:text-slate-900'
                              >
                                <PencilSquareIcon className='h-4 w-4' />
                              </button>
                            )}
                          </div>

                          {selectedSectionTitleIndex === sectionIndex && (
                            <button
                              onClick={() => {
                                const updatedTitle = getValues(
                                  `sections.${sectionIndex}.title`,
                                );

                                updateModuleTitle({
                                  moduleID: (section as Section).section_id,
                                  title: updatedTitle,
                                });
                              }}
                              type='button'
                              disabled={isUpdatingTitle}
                              className='mt-2 mb-2 font-semibold flex items-center justify-center text-sm h-[40px] min-w-[68px] px-8 rounded-md border-[1px] border-[#e5e7eb] hover:text-primary hover:border-primary text-[#828282]'
                            >
                              {isUpdatingTitle ? (
                                <Spinner
                                  className='mr-2'
                                  size='sm'
                                  variant='primary'
                                />
                              ) : null}
                              Update Module Title
                            </button>
                          )}
                          <p className={styles.error}>
                            {
                              errors.sections?.[sectionIndex]?.title
                                ?.message as string
                            }
                          </p>
                        </div>
                      </div>

                      {!(section as Section).section_id && (
                        <div className='flex-1 flex items-end'>
                          <button
                            disabled={!watch(`sections.${sectionIndex}.title`)}
                            onClick={() => {
                              createNewSection({
                                title: getValues(
                                  `sections.${sectionIndex}.title`,
                                ),
                                order: sectionIndex,
                                course: defaultValues.course_id as string,
                                session_id: id as string,
                              });
                            }}
                            type='button'
                            className='mb-2 font-semibold flex items-center justify-center text-sm h-[40px] min-w-[68px] px-8 rounded-md border-[1px] border-[#e5e7eb] hover:text-primary hover:border-primary text-[#828282]'
                          >
                            {isCreateNewSectionPending ? (
                              <Spinner
                                className='mr-2'
                                size='sm'
                                variant='light'
                              />
                            ) : (
                              <PlusIcon className='h-4 w-4 mr-2 text-slate-500' />
                            )}
                            Save Module
                          </button>
                        </div>
                      )}
                    </div>

                    {(section as Section).section_id && (
                      <div className='mt-4'>
                        <h5 className='font-bold text-slate-500'>
                          Module Content
                        </h5>
                        <Controller
                          control={control}
                          name={`sections.${sectionIndex}.resources`}
                          render={({ field: { onChange, value } }) => (
                            <>
                              {value?.map((resource, resourceIndex) => {
                                return (
                                  <>
                                    <div
                                      key={resourceIndex}
                                      className='flex flex-col sm:flex-row mt-2'
                                    >
                                      <div className='flex-1 mr-4'>
                                        <label
                                          htmlFor={`sections.${sectionIndex}.resources.${resourceIndex}.title`}
                                          className={styles.label}
                                        >
                                          Title
                                          <span className='text-red-500 ml-1'>
                                            *
                                          </span>
                                        </label>
                                        <input
                                          value={resource.title}
                                          onChange={(e) => {
                                            const newSections = [...value];
                                            newSections[resourceIndex].title =
                                              e.target.value;
                                            if (uploadedFile) {
                                              newSections[
                                                resourceIndex
                                              ].uploadContent = uploadedFile;
                                            }
                                            onChange(newSections);
                                          }}
                                          placeholder='Module title'
                                          className={`border p-2 rounded w-full ${!isEditingContent ? 'readonly-input' : ''}`}
                                          readOnly={
                                            isViewMode ||
                                            !isEditingContent ||
                                            !!resource.presigned_url
                                          }
                                        />
                                        <p className={styles.error}>
                                          {
                                            errors.sections?.[sectionIndex]
                                              ?.resources?.[resourceIndex]
                                              ?.title?.message as string
                                          }
                                        </p>
                                      </div>
                                      <div className='flex-1'>
                                        <label
                                          htmlFor={`sections.${sectionIndex}.resources.${resourceIndex}.uploadContent`}
                                          className={styles.label}
                                        >
                                          Upload Content
                                          <span className='text-red-500 ml-1'>
                                            *
                                          </span>
                                        </label>

                                        <Controller
                                          control={control}
                                          name={`sections.${sectionIndex}.resources.${resourceIndex}.uploadContent`}
                                          render={({ field: { onChange } }) => {
                                            return (
                                              <>
                                                {resource.file_type?.includes(
                                                  'mp4',
                                                ) ? (
                                                  // eslint-disable-next-line jsx-a11y/media-has-caption
                                                  <video
                                                    controls
                                                    className='w-full'
                                                  >
                                                    <source
                                                      src={
                                                        resource.presigned_url
                                                      }
                                                      type='video/mp4'
                                                    />
                                                    Your browser does not
                                                    support the video tag.
                                                  </video>
                                                ) : resource.file_type &&
                                                  resource.file_type !==
                                                    'video/mp4' ? (
                                                  <a
                                                    href={
                                                      resource.presigned_url
                                                    }
                                                    download
                                                    target='blank'
                                                    className='text-blue-400 hover:underline'
                                                  >
                                                    {
                                                      resource.file_url
                                                        ?.split('.com/')[1]
                                                        .split('/')[1]
                                                    }
                                                  </a>
                                                ) : (
                                                  <input
                                                    type='file'
                                                    onChange={(e) => {
                                                      setUploadedFile(
                                                        e.target.files?.[0],
                                                      );
                                                      onChange(
                                                        e.target.files?.[0] ||
                                                          null,
                                                      );
                                                    }}
                                                    className={`${styles.input} ${!isEditingContent ? 'readonly-input' : ''}`}
                                                    data-cy={`sections.${sectionIndex}.resources.${resourceIndex}.uploadContent`}
                                                    disabled={
                                                      isViewMode ||
                                                      !isEditingContent
                                                    }
                                                  />
                                                )}
                                              </>
                                            );
                                          }}
                                        />
                                        <p className={styles.error}>
                                          {
                                            errors.sections?.[sectionIndex]
                                              ?.resources?.[resourceIndex]
                                              ?.uploadContent?.message as string
                                          }
                                        </p>
                                      </div>

                                      <div className='mt-5'>
                                        <button
                                          type='button'
                                          onClick={() => {
                                            if (
                                              (section as Section).section_id &&
                                              (resource as Resource).id
                                            ) {
                                              setselectedResource(
                                                (resource as Resource).id,
                                              );
                                              deleteResource({
                                                resourceID: (
                                                  resource as Resource
                                                ).id,
                                                sectionID: (section as Section)
                                                  .section_id,
                                                sessionID: id as string,
                                                successCB: onChange,
                                              });
                                            } else {
                                              const newResources = value.filter(
                                                (_: any, i: number) =>
                                                  i !== resourceIndex,
                                              );
                                              onChange(newResources);
                                            }
                                          }}
                                          className='text-slate-400 hover:text-red-500 ml-4 mt-2 sm:mt-0'
                                          disabled={
                                            isViewMode || !isEditingContent
                                          }
                                        >
                                          {isDeleteResourcePending &&
                                          selectedResource ===
                                            (resource as Resource).id ? (
                                            <Spinner
                                              className='mr-2'
                                              size='sm'
                                              //variant='light'
                                            />
                                          ) : (
                                            <TrashIcon className='h-5 w-5' />
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                    {!resource.presigned_url && (
                                      <div className='flex-1 flex items-end'>
                                        <button
                                          disabled={
                                            !watch(
                                              `sections.${sectionIndex}.resources.${resourceIndex}.uploadContent`,
                                            ) ||
                                            !watch(
                                              `sections.${sectionIndex}.resources.${resourceIndex}.title`,
                                            ) ||
                                            isCreateNewResourcePending
                                          }
                                          onClick={() => {
                                            createNewResource({
                                              file: getValues(
                                                `sections.${sectionIndex}.resources.${resourceIndex}.uploadContent`,
                                              ),
                                              title: getValues(
                                                `sections.${sectionIndex}.resources.${resourceIndex}.title`,
                                              ),
                                              order: resourceIndex,
                                              section_id: (
                                                sections[
                                                  sectionIndex
                                                ] as Section
                                              ).section_id,
                                              session_id: id as string,
                                            });
                                          }}
                                          type='button'
                                          className='mb-2 font-semibold flex items-center justify-center text-sm h-[40px] min-w-[68px] px-8 rounded-md border-[1px] border-[#e5e7eb] hover:text-primary hover:border-primary text-[#828282]'
                                        >
                                          {isCreateNewResourcePending ? (
                                            <Spinner
                                              className='mr-2'
                                              size='sm'
                                              variant='light'
                                            />
                                          ) : (
                                            <PlusIcon className='h-4 w-4 mr-2' />
                                          )}
                                          Save Resource
                                        </button>
                                      </div>
                                    )}
                                  </>
                                );
                              })}
                              {!isCreateNewResourcePending && (
                                <button
                                  type='button'
                                  className='mt-2 text-primary flex items-center italic font-semibold text-xs mb-6r'
                                  onClick={() =>
                                    onChange([
                                      ...(value || []),
                                      {
                                        title: '',
                                        uploadContent: null,
                                      },
                                    ])
                                  }
                                  disabled={!isEditingContent}
                                >
                                  <PlusIcon className='h-4 w-4 mr-1' />
                                  Add New Content
                                </button>
                              )}
                            </>
                          )}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
              <button
                type='button'
                className='text-primary text-sm flex items-center italic font-semibold mt-2'
                onClick={() =>
                  appendSectionField({
                    title: '',
                    order: sections.length,
                    resources: [],
                  })
                }
                disabled={!isEditingContent}
              >
                <PlusIcon className='h-4 w-4 mr-1' />
                Add New Module
              </button>
            </div>
          )}
        </form>
      </div>
      {(isImgUploadPending || isCreateNewResourcePending) && (
        <Loader isLoading={isImgUploadPending || isCreateNewResourcePending} />
      )}
    </>
  );
};
