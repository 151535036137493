type LoaderProps = {
  isLoading: boolean;
  msg?: string;
};

export const Loader: React.FC<LoaderProps> = ({
  isLoading,
  msg = 'Please wait...',
}) => {
  return isLoading ? (
    <div className='loader-overlay flex flex-col'>
      <div className='loader-spinner'></div>
      <p className='text-sm text-slate-100'>{msg}</p>
    </div>
  ) : null;
};
